.string-diff {
    color: black;
}
.string-diff .removed {
    color: red;
    background: #ffd8d8;
    text-decoration: line-through;
}
.string-diff .added {
    color: green;
    background: #d9ffd9;
}
