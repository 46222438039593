.animation-overlay {
    inset: 0;
    animation: overlayShow 500ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
