.survey-table-view .ant-typography-edit-content {
    width: 100%;
    display: inline-block;
    margin-bottom: 0px;
}

.survey-table-view .ant-table-cell-with-append {
    display: flex;
}

.survey-table-view .node-section {
    background: #eef8ff;
}

.survey-table-view .node-question {
    background: #f8f8e9;
}

.survey-table-view .key-display {
    color: var(--text-secondary-color);
}

.survey-table-view .comment-action {
    cursor: pointer;
}

.survey-table-view .comment-action .no-comments {
    opacity: 0;
}

.survey-table-view .comment-action:hover {
    opacity: 1;
}

.survey-table-view .comment-count-badge {
    margin: 0px 5px;
}

.survey-table-view .center-col {
    text-align: center;
}

.survey-table-view th.center-col.eval-tag {
    background: var(--background-secondary);
}
.survey-table-view th.center-col.options {
    background: orange;
}
.survey-table-view th.center-col.variant {
    background: lightgreen;
}

.survey-table-view .dragging .ant-table-cell {
    background: var(--background-secondary) !important;
    box-shadow: 0 -1px 4px 0 #00000026;
}

.survey-table-view .drop-over-upward.not-allowed .ant-table-cell {
    border-top-color: var(--danger-color) !important;
}

.survey-table-view tr:focus {
    outline: none;
}

.survey-table-view tr td {
    border: 1px solid transparent;
}

.survey-table-view tr.selected td {
    border: 1px dotted var(--selected-border-color);
}

.survey-table-view tr.cut-copy td {
    border: 1px dashed var(--highlight-color);
}

.survey-table-view tr.drop-over-downward td {
    border-bottom: 2px dashed var(--highlight-color);
}

.survey-table-view tr.drop-over-upward td {
    border-top: 2px dashed var(--highlight-color);
}

.survey-table-view .ant-table-tbody tr:focus.ant-table-row:hover td {
    background: none;
}

.survey-table-view .table-wrapper {
    outline: none;
}

.survey-table-view th {
    position: sticky !important;
    top: 0;
    z-index: 1;
}

.ant-tabs {
    overflow: inherit !important;
}

.survey-table-view .ant-table-tbody > tr.node-option > td.ant-table-cell {
    padding: 4px;
}

.react-select-borderless {
    border: none;
    box-shadow: none;
}
.react-select-borderless .react-select__control {
    border: none;
    box-shadow: none;
}
.react-select-borderless .react-select__indicator-separator {
    display: none;
}
