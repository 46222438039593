#drawer .ant-tabs-ink-bar {
    border-bottom: 2px solid #c71585;
}

#drawer .ant-tabs-content-holder {
    display: flex;
    flex-direction: column;
}

#drawer .ant-tabs-content-holder > div {
    flex: 1;
}

#drawer .ant-tabs.ant-tabs-top {
    display: flex;
    height: 100%;
}

#drawer .ant-tabs-content.ant-tabs-content-top {
    height: 100%;
}

#drawer .ant-tabs-tabpane {
    height: 100%;
}
