.logo-spinner.small {
    width: 63px;
}

.logo-spinner.middle {
    width: 126px;
}

.logo-spinner.large {
    width: 189px;
    height: 144px;
}

.logo-spinner.xlarge {
    width: 252px;
    height: 192px;
}

.logo-spinner.state-spinning #cog-path path {
    animation: spinning-cog 2.2s infinite ease;
}

.logo-spinner.state-open {
    animation: growing-cog 0.3s 1 ease forwards;
}

.logo-spinner.state-close {
    animation: shrinking-cog 0.3s 1 ease forwards;
}

@keyframes spinning-cog {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(-45deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes growing-cog {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes shrinking-cog {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(0);
    }
}
