.survey-command-panel .ant-card-body {
    padding: 10px;
}

.survey-command-panel .ant-card {
    margin-bottom: 5px;
}

.survey-command-panel .new-comment-form .ant-row {
    margin-bottom: 5px;
}

.survey-command-panel .ant-comment-inner {
    padding: 6px 0px;
}

.editor-tabs,
.editor-tabs .ant-tabs-content {
    height: 100%;
}
