.editable-cell {
    display: flex;
    cursor: pointer;
}

.editable-cell .actions {
    opacity: 0;
    display: flex;
    position: absolute;
    right: 0px;
    background: #ffffffe1;
    border: 1px solid var(--text-secondary-color);
    padding-left: 10px;
    margin-right: 5px;
    border-radius: 15px;
}

.ant-table-cell:hover .actions {
    opacity: 1;
    transition: 0.3s;
}

.editable-cell-value-wrap {
    padding-right: 10px;
}

.editable-cell .action-icon {
    margin-right: 10px;
}

.editable-cell-value-wrap .anticon,
.editable-cell .action-icon {
    opacity: 0;
    transition: 0.3s;
}

.ant-table-cell:hover .anticon,
.ant-table-cell:hover .action-icon {
    opacity: 1;
}

.editable-cell-value-wrap.editing {
    margin-top: -3px;
    margin-left: -5px;
    margin-bottom: -4px;
    padding-left: 4px;
    padding-top: 2px;
    padding-bottom: 3px;
    line-height: inherit;
}

.editable-cell mark.search-match {
    padding-left: 0px;
    padding-right: 0px;
    background: yellow;
}

.editable-cell .default-text {
    color: #ccc;
    font-style: italic;
}
