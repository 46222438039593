.react-datepicker__close-icon::after {
    color: #dee1e4 !important;
    outline: none;
    font-size: 24px !important;
    vertical-align: baseline !important;
    background: none !important;
    display: initial !important;
}

.react-datepicker__triangle {
    display: none !important;
}

.react-datepicker__day--selected {
    background-color: #294966 !important;
}
