.custom-popover.ant-popover-placement-right .ant-popover-arrow-content,
.custom-popover.ant-popover-placement-rightTop .ant-popover-arrow-content,
.custom-popover.ant-popover-placement-rightBottom .ant-popover-arrow-content {
    transform: translateX(8px) rotate(315deg);
}

.custom-popover.ant-popover-placement-bottom .ant-popover-arrow-content,
.custom-popover.ant-popover-placement-bottomRight .ant-popover-arrow-content,
.custom-popover.ant-popover-placement-bottomLeft .ant-popover-arrow-content {
    transform: translateY(8px) rotate(45deg);
}

.custom-popover.ant-popover-placement-top .ant-popover-arrow-content,
.custom-popover.ant-popover-placement-topRight .ant-popover-arrow-content,
.custom-popover.ant-popover-placement-topLeft .ant-popover-arrow-content {
    transform: translateY(-8px) rotate(225deg);
}

.custom-popover.ant-popover-placement-left .ant-popover-arrow-content,
.custom-popover.ant-popover-placement-leftTop .ant-popover-arrow-content,
.custom-popover.ant-popover-placement-leftBottom .ant-popover-arrow-content {
    transform: translateX(-8px) rotate(135deg);
}
