/* Cookie popup style */
:root {
    --color-background: #f8f8f8;
    --color-primary: theme("colors.primary");
    --color-primary-hover: theme("colors.secondary");
    --color-secondary: theme("colors.secondary");
    --color-secondary-hover: theme("colors.slate-extra-light");
    --color-dark: theme("colors.dark");
    --color-dark-hover: rgb(11, 35, 49);
    --color-danger: theme("colors.red-primary");
    --color-danger-hover: theme("colors.red-dark");
    --color-contrast: white;

    --border-light-radius: 2px;
    --data-entry-shadow: 0 0 0 2px rgba(67, 80, 90, 0.2);

    --font-size-small: 12px;
}
