ul.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: var(--color-primary-hover);
}
ul.ant-menu-dark.ant-menu-horizontal > li.ant-menu-item:hover {
    background-color: var(--color-primary-hover);
}

ul.ant-menu.ant-menu-dark {
    background: var(--color-dark);
}
