:root {
    --background-secondary: #ced4ff;
    --danger-color: #ff4d4f;
    --text-secondary-color: #cacaca;
    --selected-border-color: #6d6d6d;
    --highlight-color: #1890ff;
}

.markdown > p {
    margin-bottom: 0;
}

#root {
    min-height: 100%;
    height: 100%;
    background: #f0f2f5;
    counter-reset: report-list-item;
}

.content {
    /* max-width:  1500px; */
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
}

.public-page {
    text-align: center;
}

table {
    width: 100%;
}

.nav-item .nav-link.active {
    background: #282c34;
}

header .logo {
    float: left;
    margin: 10px 10px 0px 0;
    cursor: pointer;
}

header .right-menu {
    position: absolute;
    right: 20px;
    top: 3px;
}

header .right-menu .ant-avatar {
    margin-left: 4px;
}

.sub-ul {
    list-style-type: none;
}

.item-list-item {
    counter-increment: report-list-item 1;
}

.item-list-item:not(:last-child)::after {
    content: ", ";
    color: rgba(0, 0, 0, 0.85);
    cursor: default;
}

.item-list-item:nth-last-child(2)::after {
    content: " and ";
    color: rgba(0, 0, 0, 0.85);
    cursor: default;
}

.item-list-item:last-child > span:after {
    content: " ";
}

.item-list-item:first-child:last-child > span {
    display: none;
}

.ant-table-summary td {
    font-weight: 600;
    background-color: #fafafa;
}

.ant-tabs-content-holder {
    display: flex;
    flex-direction: column;
}

.ant-tabs-content-holder > div {
    min-height: 95%;
    flex: 1;
}

td.ant-table-column-sort {
    background-color: transparent; /* remove default styling on sorted column */
}

th.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
    z-index: 10;
}

.ant-table table tr td {
    @apply min-h-15;
}
