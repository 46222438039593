.error-message {
    color: var(--color-danger);
    font-style: italic;
    font-size: 0.75rem;
}

input.error-field,
input.error-field:focus {
    border-color: var(--color-danger);
}
