@import "~@vaultinum/vaultinum-sdk/dist/style.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@layer components {
    td.missing-tool {
        @apply bg-red-500;
    }
}

.ant-layout-header {
    background: var(--color-dark);
}

.ant-form legend {
    border-bottom: 0;
}

.ant-tabs-ink-bar {
    background: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #c71585;
}
